exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-dynamic-pages-all-wp-job-tsx": () => import("./../../../src/templates/dynamic-pages/allWpJob.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-all-wp-job-tsx" */),
  "component---src-templates-dynamic-pages-all-wp-post-tsx": () => import("./../../../src/templates/dynamic-pages/allWpPost.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-all-wp-post-tsx" */),
  "component---src-templates-dynamic-pages-search-tsx": () => import("./../../../src/templates/dynamic-pages/search.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-search-tsx" */),
  "component---src-templates-wp-einrichtung-tsx": () => import("./../../../src/templates/WpEinrichtung.tsx" /* webpackChunkName: "component---src-templates-wp-einrichtung-tsx" */),
  "component---src-templates-wp-form-tsx": () => import("./../../../src/templates/WpForm.tsx" /* webpackChunkName: "component---src-templates-wp-form-tsx" */),
  "component---src-templates-wp-job-tsx": () => import("./../../../src/templates/WpJob.tsx" /* webpackChunkName: "component---src-templates-wp-job-tsx" */),
  "component---src-templates-wp-page-tsx": () => import("./../../../src/templates/WpPage.tsx" /* webpackChunkName: "component---src-templates-wp-page-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/WpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */)
}

